<script lang="ts" setup>
const { remoteConfig } = useConfigStore();

const link = remoteConfig.SocialTelegramBot;
</script>

<template>
    <a
        :href="link"
        target="_blank"
        rel="noopener noreferrer"
        title="Написать в Telegram"
        class="p-2 rounded-full fixed bottom-40 right-4 md:bottom-24 md:right-6 bg-blue-500 hover:bg-blue-600 transition-all duration-300 shadow-lg z-50"
    >
        <IconSocialTelegram :size="2.3" class="text-white" />
    </a>
</template>

<style scoped></style>
